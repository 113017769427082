import firebase from '@/database/db';
import { getAuth, onAuthStateChanged} from "firebase/auth";

const auth = getAuth(firebase.app());

export default () => new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });