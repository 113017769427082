<template>
    <div class="fullscreen-wrapper" @click="$emit('close')">
        <div class="fullscreen-container">
            <img class="fullscreen-img" :src="src" alt="">
        </div>
    </div>
</template>

<script>
export default {
name: "FullscreenImage",
props: {
    src: String, 
},
}
</script>

<style scoped>
.fullscreen-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    backdrop-filter: blur(4px);
}
.fullscreen-container {
    padding: 2.5%;
}
.fullscreen-img {
    position: sticky;
    width: 100%;
}
</style>