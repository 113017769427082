<template>
  <div class="Sponsors">
    <div class="btn" @click="previous">
      <span class="material-icons">keyboard_arrow_left</span>
    </div>
    <div
      class="SponsorsGrid"
      tabindex="0"
      @keydown.left="previous"
      @keydown.right="next"
    >
      <div class="img-wrapper" v-for="(sponsor, i) in threeSponsors" :key="i">
        <img
          v-if="!sponsor.link"
          :src="sponsor.img"
          :alt="sponsor.name + ' logo'"
        />
        <a
          v-else
          tabindex="0"
          :href="sponsor.link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img :src="sponsor.img" :alt="sponsor.name + ' logo'"
        /></a>
      </div>
    </div>
    <div class="btn" @click="next">
      <span class="material-icons">keyboard_arrow_right</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SponsorsSlide",
  data: () => ({
    selected: {
      min: 0,
      max: 3,
    },
    sponsors: [
      {
        name: "Larvik Kommune",
        img: require("@/assets/sponsor-list/Larvik Kommune.png"),
        link: "",
      },
      {
        name: "ABAX",
        img: require("@/assets/sponsor-list/ABAX.png"),
        link: "",
      },
      {
        name: "AMFI Larvik",
        img: require("@/assets/sponsor-list/AMFI Larvik.jpg"),
        link: "https://amfi.no/amfi-larvik/?gclid=CjwKCAiAl9efBhAkEiwA4ToriqsOSmIZI7QzVh6VHX4zmbGZgDe-Gj9MhOUskgvx5Hi4AD0RyJD_JhoCkOEQAvD_BwE&gclsrc=aw.ds",
      },
      {
        name: "Arnes Fargehandel",
        img: require("@/assets/sponsor-list/Arnes Fargehandel.jpg"),
        link: "",
      },
      {
        name: "Bergene Holm",
        img: require("@/assets/sponsor-list/Bergene Holm.png"),
        link: "",
      },
      {
        name: "Biblioteksystemer",
        img: require("@/assets/sponsor-list/Biblioteksystemer.png"),
        link: "",
      },
      {
        name: "Danske Bank",
        img: require("@/assets/sponsor-list/Danske Bank.png"),
        link: "",
      },
      {
        name: "DNB",
        img: require("@/assets/sponsor-list/DNB.png"),
        link: "",
      },
      {
        name: "DNT",
        img: require("@/assets/sponsor-list/DNT.jpg"),
        link: "https://lot.dnt.no",
      },
      {
        name: "Eckbos Legat",
        img: require("@/assets/sponsor-list/Eckbos Legat.png"),
        link: "https://www.eckbos-legat.no",
      },
      {
        name: "EY",
        img: require("@/assets/sponsor-list/EY.png"),
        link: "",
      },
      {
        name: "Farris Bad",
        img: require("@/assets/sponsor-list/Farris Bad.jpeg"),
        link: "",
      },
      {
        name: "Fritzoe Eiendom",
        img: require("@/assets/sponsor-list/Fritzoe Eiendom.png"),
        link: "https://fritzoeeiendom.no",
      },
      {
        name: "GjensidigeStiftelsen",
        img: require("@/assets/sponsor-list/GjensidigeStiftelsen.jpeg"),
        link: "https://www.gjensidigestiftelsen.no",
      },
      {
        name: "Grandkvartalet",
        img: require("@/assets/sponsor-list/Grandkvartalet.png"),
        link: "",
      },
      {
        name: "Handelsbanken",
        img: require("@/assets/sponsor-list/Handelsbanken.png"),
        link: "",
      },
      {
        name: "Ingers Hage",
        img: require("@/assets/sponsor-list/Ingers Hage.png"),
        link: "https://www.facebook.com/ingershage/",
      },
      {
        name: "Kaare Mortensen",
        img: require("@/assets/sponsor-list/Kaare Mortensen.png"),
        link: "",
      },
      {
        name: "Klemmetsby-Firing Begravelsesbyrå",
        img: require("@/assets/sponsor-list/Klemmetsby-Firing Begravelsesbyraa.png"),
        link: "https://heder.no/byraer/tonsberg-klemmetsby-firing-begravelsesbyra/",
      },
      {
        name: "Larvik Banken",
        img: require("@/assets/sponsor-list/Larvik Banken.webp"),
        link: "",
      },
      {
        name: "Larvik Boligbyggelag",
        img: require("@/assets/sponsor-list/Larvik Boligbyggelag.png"),
        link: "",
      },
      {
        name: "Larvik Bys Vel",
        img: require("@/assets/sponsor-list/Larvik Bys Vel.png"),
        link: "https://larvikbysvel.no",
      },
      {
        name: "Larvik Havn",
        img: require("@/assets/sponsor-list/Larvik Havn.png"),
        link: "",
      },
      {
        name: "Larviks Sjømannsforening",
        img: require("@/assets/sponsor-list/Larviks Sjomannsforening.png"),
        link: "",
      },
      {
        name: "LO Larvik",
        img: require("@/assets/sponsor-list/LO Larvik.png"),
        link: "https://www.facebook.com/LOILARVIK/",
      },
      {
        name: "Lundhs",
        img: require("@/assets/sponsor-list/Lundhs.png"),
        link: "https://lundhs.no",
      },
      {
        name: "Nasta",
        img: require("@/assets/sponsor-list/Nasta.png"),
        link: "https://www.nasta.no",
      },
      {
        name: "NLI",
        img: require("@/assets/sponsor-list/NLI.png"),
        link: "https://www.nli.no",
      },
      {
        name: "Nopa",
        img: require("@/assets/sponsor-list/Nopa.png"),
        link: "https://nopa.no",
      },
      {
        name: "Norsk Stål",
        img: require("@/assets/sponsor-list/Norsk Staal.png"),
        link: "",
      },
      {
        name: "Sparebank-1",
        img: require("@/assets/sponsor-list/Sparebank-1.jpg"),
        link: "",
      },
      {
        name: "Strandberg Stein",
        img: require("@/assets/sponsor-list/Strandberg Stein.webp"),
        link: "https://www.strandbergstein.no",
      },
      {
        name: "Thor Heyerdahl Instituttet",
        img: require("@/assets/sponsor-list/Thor Heyerdahl Instituttet.png"),
        link: "https://vestfoldmuseene.no/thor-heyerdahl-instituttet",
      },
      {
        name: "Treschow Fritzøe",
        img: require("@/assets/sponsor-list/Treschow Fritzoe.png"),
        link: "https://fritzoe.no/fgts-minnefond/",
      },
      {
        name: "Tveter Lund",
        img: require("@/assets/sponsor-list/Tveter Lund.png"),
        link: "https://www.tveterlund.no",
      },
      {
        name: "VKT",
        img: require("@/assets/sponsor-list/VKT.png"),
        link: "https://www.vkt.no",
      },
      {
        name: "GET Academy",
        img: require("@/assets/logo.png"),
        link: "https://getacademy.no/",
      },
    ],
  }),
  methods: {
    next() {
      if (
        this.sponsors.slice(this.selected.min + 3, this.selected.max + 3)
          .length > 0
      ) {
        this.selected.min = this.selected.min + 3;
        this.selected.max = this.selected.max + 3;
      } else {
        this.selected.min = 0;
        this.selected.max = 3;
      }
    },
    previous() {
      this.selected.min = this.selected.min - 3;
      this.selected.max = this.selected.max - 3;
      if (this.selected.min < 0) {
        this.selected.min = this.sponsors.length + this.selected.min;
        this.selected.max = this.sponsors.length + this.selected.max;
      }
    },
  },
  computed: {
    threeSponsors() {
      return this.sponsors.slice(this.selected.min, this.selected.max);
    },
  },
};
</script>

<style scoped lang="scss">
.Sponsors {
  height: 15vh;
  display: grid;
  grid-template-columns: 10% auto 10%;
  justify-content: space-between;
  align-items: stretch;
  border: 3px solid #2a8355;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
  user-select: none;
}
.SponsorsGrid {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background-repeat: no-repeat;
  align-items: center;
  img {
    height: 5vw;
    width: 100%;
    object-fit: scale-down;
  }
}

.material-icons {
  font-size: 2.5em;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .Sponsors {
    height: 45vh;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    grid-template-columns: 20% auto 20%;
  }
  .SponsorsGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    img {
      height: 5em;
      width: 100%;
    }
  }
}
</style>
