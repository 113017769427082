<template>
  <div
    class="InstallationSlider"
    @click="redirectToInstallation(installations[selected])"
    @keypress.enter="redirectToInstallation(installations[selected])"
    @keydown.left="prev"
    @keydown.right="next"
    tabindex="0"
  >
    <div>
      <span class="material-icons carousel-arrow" @click.stop="prev"
        >arrow_circle_left</span
      >
    </div>
    <div class="installation-image-container">
      <img
      v-if="installations[this.selected]"
        class="installation-image"
        :src="getImage()"
        :alt="`Bilde av ${installations[selected].title}`"
      />
    </div>
    <div>
      <span class="material-icons carousel-arrow" @click.stop="next"
        >arrow_circle_right</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallationSlider",
  data: () => ({
    installations: [],
    selected: 0,
  }),
  async mounted() {
    await this.getInstallations();
  },
  methods: {
    async getInstallations() {
      await this.$store.dispatch("getInstallations");
      this.installations = await this.$store.state.installations;
      this.installations = this.installations.sort(
        (a, b) => a.id - b.id
      );
    },
    getImage () {
      return this.installations[this.selected].img != undefined ? 
      this.installations[this.selected].img : require('../assets/fallback.jpg');
    },
    next() {
      if (this.installations[this.selected + 1]) this.selected++;
      else this.selected = 0;
    },
    prev() {
      if (this.installations[this.selected - 1]) this.selected--;
      else this.selected = this.installations.length - 1;
    },
    redirectToInstallation(inst) {
      this.$router.push({ name: "installasjon", params: inst });
    },
  },
};
</script>

<style scoped lang="scss">
.InstallationSlider {
  background-color: black;
  height: fit-content;
  margin: auto;
  display: grid;
  grid-template-columns: 15% auto 15%;
}

.InstallationSlider div:nth-child(odd) {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.installation-image-container {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.installation-image {
  object-fit: contain;
  max-width: 45vw;
  color: #fff;
}

.material-icons {
  font-size: 2.5em;
  border: solid 3px #2a8355;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-arrow {
  user-select: none;
}
</style>
