<template>
  <div class="row">
    <div class="col-12">
      <div class="adminWrapper">
        <h3 v-if="domain == 'poesiparken.no'" class="incorrect-path-name">
          Du er på feil side, gå til
          <a href="https://poesiparken.web.app/"
            >https://poesiparken.web.app/</a
          >
        </h3>
        <div class="top">
          <span class="username" v-if="this.$store.state.user"
            >Admin ({{ this.$store.state.user.displayName }})</span
          >
          <button @click="logout">
            <span class="material-icons">logout</span> Logg ut
          </button>
        </div>
        <div class="actions">
          <button @click="view = 0" :class="{ activeButton: view == 0 }">
            <span class="material-icons">library_add</span> Installasjoner
          </button>
          <button @click="view = 1" :class="{ activeButton: view == 1 }">
            <span class="material-icons">newspaper</span> Aktuelt
          </button>
          <button @click="view = 2" :class="{ activeButton: view == 2 }">
            <span class="material-icons">groups</span> Personer
          </button>
        </div>
        <div class="view">
          <div v-if="view == 0">
            <div class="postsWrapper">
              <div class="pTop">
                <input
                  @keydown="resetInstallationsFilter()"
                  type="search"
                  style="margin-right: 10px"
                  placeholder="Søk etter installasjon"
                  v-model="filterInstallationsSearchText"
                />
                <button @click="showInstallations = true">
                  Legg til installasjon <span class="material-icons">add</span>
                </button>
              </div>
              <div class="posts">
                <div
                  class="post"
                  v-for="(n, i) in filterInstallations()"
                  :key="i"
                >
                  <div class="inst-item-row">
                    <span>#{{ n.id }} - {{ n.title }}</span>
                  </div>
                  <div class="inst-item-row inst-item-row-2">
                    <button class="edit-button" @click="editInstallation(n)">
                      Rediger
                    </button>
                    <button
                      class="delete-button"
                      @click="deleteInstallation(n)"
                    >
                      Slett
                    </button>
                  </div>
                </div>
              </div>
              <div class="pagination">
                <button @click="prev()">
                  <span class="material-icons">keyboard_arrow_left</span>Forrige
                </button>
                <button @click="next()">
                  Neste <span class="material-icons">keyboard_arrow_right</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="view == 1">
            <div class="postsWrapper">
              <div class="pTop">
                <button @click="showNewsItems = true">
                  Legg til nyhet <span class="material-icons">add</span>
                </button>
              </div>
              <div class="posts">
                <div class="post" v-for="(n, i) in newsItems" :key="i">
                  <span
                    >{{ n.title }}. Tid opplastet:
                    <span>{{
                      ("0" + new Date(n.authorTimestamp).getHours()).slice(-2)
                    }}</span
                    >:<span>{{
                      ("0" + new Date(n.authorTimestamp).getMinutes()).slice(-2)
                    }}</span
                    >:<span
                      >{{
                        ("0" + new Date(n.authorTimestamp).getSeconds()).slice(
                          -2
                        )
                      }}
                      -
                    </span>
                    <span>
                      {{
                        ("0" + new Date(n.authorTimestamp).getDate()).slice(-2)
                      }}</span
                    >/<span>{{
                      (
                        "0" +
                        (new Date(n.authorTimestamp).getMonth() + 1)
                      ).slice(-2)
                    }}</span
                    >/<span>{{
                      new Date(n.authorTimestamp).getFullYear()
                    }}</span>
                  </span>
                  <div class="inst-item-row inst-item-row-2">
                    <button class="edit-button" @click="editNewsItem(n)">
                      Rediger
                    </button>
                    <button class="delete-button" @click="deleteNewsItem(n)">
                      Slett
                    </button>
                  </div>
                </div>
              </div>
              <div class="pagination">
                <button>
                  <span class="material-icons">keyboard_arrow_left</span>Forrige
                </button>
                <button>
                  Neste <span class="material-icons">keyboard_arrow_right</span>
                </button>
              </div>
            </div>
          </div>

          <div v-if="view == 2">
            <div class="postsWrapper">
              <div class="pTop">
                <button @click="showPeople = true">
                  Legg til personer <span class="material-icons">add</span>
                </button>
              </div>
              <div class="posts">
                <div class="post" v-for="(n, i) in people" :key="i">
                  <span
                    >{{ n.group.slice(0, 6) }} - #{{ n.position }} -
                    {{ n.name }}</span
                  >
                  <div class="inst-item-row inst-item-row-2">
                    <button class="edit-button" @click="editPerson(n)">
                      Rediger
                    </button>
                    <button class="delete-button" @click="deletePerson(n)">
                      Slett
                    </button>
                  </div>
                </div>
              </div>
              <div class="pagination">
                <button>
                  <span class="material-icons">keyboard_arrow_left</span>Forrige
                </button>
                <button>
                  Neste <span class="material-icons">keyboard_arrow_right</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalInstallations
      @close="closeAddInstallations()"
      v-if="showInstallations && view === 0"
    />
    <ModalInstallationsEdit
      @close="closeEditInstallations()"
      v-if="showInstallationsEdit && view === 0"
      :selectedInstallation="selectedInstallation"
    />
    <ModalNews
      @close="closeAddNewsItems()"
      v-if="showNewsItems && view === 1"
    />
    <ModalNewsEdit
      @close="closeEditNewsItems()"
      v-if="showNewsItemsEdit && view === 1"
      :selectedNewsItem="selectedNewsItem"
    />
    <ModalPeople @close="closeAddPeople()" v-if="showPeople && view === 2" />
    <ModalPeopleEdit
      @close="closeEditPerson()"
      v-if="showPersonEdit && view === 2"
      :selectedPerson="selectedPerson"
    />
  </div>
</template>
  
<script>
import ModalNews from "@/components/modals/news/ModalNews.vue";
import ModalNewsEdit from "@/components/modals/news/ModalNewsEdit.vue";
import ModalInstallations from "@/components/modals/installations/ModalInstallations.vue";
import ModalInstallationsEdit from "@/components/modals/installations/ModalInstallationsEdit.vue";
import ModalPeople from "@/components/modals/people/ModalPeople.vue";
import ModalPeopleEdit from "@/components/modals/people/ModalPeopleEdit.vue";
import S3 from "@/exports/s3";
export default {
  name: "AdminView",
  components: {
    ModalNews,
    ModalNewsEdit,
    ModalInstallations,
    ModalInstallationsEdit,
    ModalPeople,
    ModalPeopleEdit,
  },
  data() {
    return {
      admins: [],
      filterInstallationsSearchText: "",
      filterInstallationsStart: 0,
      filterInstallationsEnd: 10,
      filterInstallationsItemsPerPage: 10,
      view: 0,

      newsItems: null,
      showNewsItems: false,
      showNewsItemsEdit: false,
      selectedNewsItem: null,

      installations: null,
      showInstallations: false,
      showInstallationsEdit: false,
      selectedInstallation: null,

      people: null,
      showPeople: false,
      showPersonEdit: false,
      selectedPerson: null,

      domain: "",
    };
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    async getAllData() {
      this.getNewsItems();
      this.getInstallations();
      this.getPeople();
      this.getAdmins();
      this.checkDomainName();
    },
    async getNewsItems() {
      await this.$store.dispatch("getNewsItems");
      this.newsItems = await this.$store.state.newsItems;
      this.newsItems = this.newsItems.sort((a, b) => b.authorTimestamp - a.authorTimestamp);
    },
    async getInstallations() {
      await this.$store.dispatch("getInstallations");
      this.installations = await this.$store.state.installations;
    },
    async getPeople() {
      await this.$store.dispatch("getPeople");
      this.people = await this.$store.state.people;
      // this.people.group.sort();
      this.people = this.people
        ? this.people.sort((a, b) => {
          if (a.group.toUpperCase() < b.group.toUpperCase()) return 1;
          if (a.group.toUpperCase() > b.group.toUpperCase()) return -1;
          if (a.position < b.position) return -1;
          if (a.position > b.position) return 1;
          // a.position - b.position;
          })
        : [];
    },

    async getAdmins() {
      await this.$store.dispatch("getAdmins");

      // FJERN KOMMENTAR UNDER NÅR VI ER FERDIG

      // this.admins = this.$store.state.admins;
      // if (this.$store.state.user == null) {
      //   this.$router.push({ name: "home" });
      // }
      // if (this.admins.indexOf(this.$store.state.user.uid) != -1) {
      //   this.$router.push({ name: "home" });
      // }
    },

    checkDomainName() {
      this.domain = window.location.host;
    },

    async editNewsItem(n) {
      this.showNewsItemsEdit = true;
      this.selectedNewsItem = n;
      this.getNewsItems();
    },
    async editInstallation(n) {
      this.showInstallationsEdit = true;
      this.selectedInstallation = n;
      await this.getInstallations();
    },
    editPerson(n) {
      this.showPersonEdit = true;
      this.selectedPerson = n;
      this.getPeople();
    },
    async closeAddInstallations() {
      this.showInstallations = false;
      await this.getInstallations();
    },
    async closeEditInstallations() {
      this.showInstallationsEdit = false;
      await this.getInstallations();
    },
    async closeAddPeople() {
      this.showPeople = false;
      this.getPeople();
    },
    async closeEditPerson() {
      this.showPersonEdit = false;
      this.getPeople();
    },
    async closeAddNewsItems() {
      this.showNewsItems = false;
      this.getNewsItems();
    },
    async closeEditNewsItems() {
      this.showNewsItemsEdit = false;
      this.getNewsItems();
    },
    async logout() {
      await this.$store.dispatch("logoutWithFirebase");
      this.$router.push({ path: "/" });
    },
    async deleteInstallation(n) {
      let payload = n;
      const response = confirm(
        `Er du sikker på at du har lyst til å slette installasjon #${n.id} - ${n.title}`
      );
      if (response) {
        if (n.img != undefined || n.img)
          S3.delete({
            Bucket: "poesiparken",
            Key: decodeURIComponent(
              `${n.img.split("/")[3]}/${n.img.split("/")[4]}`
            ),
          })
            .then(async () => {
              await this.$store.dispatch("deleteInstallation", payload);
              await this.getInstallations();
            })
        else {
          await this.$store.dispatch("deleteInstallation", payload);
          await this.getInstallations();
        }
      }
    },
    async deleteNewsItem(n) {
      let payload = n;
      const response = confirm(
        `Er du sikker på at du har lyst til å slette nyheten?`
      );
      if (response) {
        if (n.img != undefined || n.img)
          S3.delete({
            Bucket: "poesiparken",
            Key: decodeURIComponent(
              `${n.img.split("/")[3]}/${n.img.split("/")[4]}`
            ),
          })
            .then(async () => {
              await this.$store.dispatch("deleteNewsItem", payload);
              await this.getNewsItems();
            })
        else {
          await this.$store.dispatch("deleteNewsItem", payload);
          await this.getNewsItems();
        }
      }
    },
    deletePerson(n) {
      const response = confirm(
        `Er du sikker på at du har lyst til å slette ${n.name}`
      );
      if (response) {
        let payload = n;
        if (n.avatar) {
          const key = decodeURIComponent(
            `${n.avatar.split("/")[3]}/${n.avatar.split("/")[4]}`
          );
          S3.delete({
            Bucket: "poesiparken",
            Key: key,
          })
            .then(async () => {
              await this.$store.dispatch("deletePerson", payload);
              this.getPeople();
            })
        } else {
          (async () => {
            await this.$store.dispatch("deletePerson", payload);
            this.getPeople();
          })();
        }
      }
    },
    filterInstallations() {
      return this.installations != null
        ? this.installations
            .sort((a, b) => a.id - b.id)
            .filter(
              (ins) =>
                ins.title
                  .toLowerCase()
                  .includes(this.filterInstallationsSearchText.toLowerCase()) ||
                ins.id.toString().includes(this.filterInstallationsSearchText)
            )
            .slice(this.filterInstallationsStart, this.filterInstallationsEnd)
        : null;
    },
    next() {
      if (
        this.filterInstallationsEnd < this.installations.length &&
        this.filterInstallations().length >= 10
      ) {
        this.filterInstallationsStart += this.filterInstallationsItemsPerPage;
        this.filterInstallationsEnd += this.filterInstallationsItemsPerPage;
      }
    },
    prev() {
      if (this.filterInstallationsStart > 1) {
        this.filterInstallationsStart -= this.filterInstallationsItemsPerPage;
        this.filterInstallationsEnd -= this.filterInstallationsItemsPerPage;
      }
    },
    resetInstallationsFilter() {
      this.filterInstallationsStart = 0;
      this.filterInstallationsEnd = 10;
    },
  },
};
</script>
  
<style scoped lang="scss">
.incorrect-path-name {
  color: #832a2a;
}
.adminWrapper {
  display: grid;
  grid-template-rows: 70px 40px min-content;
  grid-gap: 10px;
}
.top {
  background: #2a8355;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 1em;
  color: white;
}
.top button {
  border: none;
  background: #cbe3d7;
  color: black;
  font-size: 1em;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actions {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(1, 4em);
  gap: 10px;
}
.actions button {
  border: none;
  background: #cbe3d7;
  color: black;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.material-icons {
  font-size: 1em;
}

.view {
  margin-top: 2em;
}

.postsWrapper {
  height: 100%;
  display: grid;
  grid-template-rows: 40px 300px 40px;
  gap: 10px;
}
.pTop {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.pTop button {
  grid-column-start: 2;
  border: none;
  background: #2a8355;
  color: white;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.posts {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  overflow-y: scroll;
}
.post {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #cbe3d7;
  padding: 10px;
}

.inst-item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    margin: 10px;
    margin-left: 0;
    padding: 10px;
    color: #fff;
    font-size: 1em;
  }
  button:hover {
    border: 2px solid #fff;
  }
  .edit-button {
    background-color: #2a8355;
  }
  .delete-button {
    background-color: #832a2a;
  }
}

.postActions {
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
}
.postActions button {
  border: none;
  background: #2a8355;
  color: white;
  font-size: 1em;
  border-radius: 50%;
  padding: 1em;
  height: 3em;
  width: 3em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
}
.pagination button {
  border: none;
  background: #2a8355;
  color: white;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* width */
.posts::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.posts::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.posts::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.posts::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.activeButton {
  background: #2a8355 !important;
  color: white !important;
}

// .mapWrapper {
//   height: 100%;
//   display: grid;
//   /* grid-template-rows: 40px 340px; */
//   gap: 10px;
// }
</style>
  