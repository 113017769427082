<template>
  <div
    class="Installation"
    @keydown.enter="redirectToInstallation"
    @click="redirectToInstallation"
    tabindex="0"
  >
    <div class="f-col">
      <div class="f-row">
        <h3>#{{ installation.id }} - {{ installation.title }}</h3>
        <p>Av: {{ installation.author }}</p>
      </div>
      <div class="f-row img-row">
        <div class="desc">
          <img
            loading="lazy"
            :src="getImage"
            :alt="'Bilde av diktet: ' + installation.title"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallationXD",
  props: {
    installation: Object,
  },
  methods: {
    redirectToInstallation() {
      let inst = JSON.parse(JSON.stringify(this.installation));
      this.$router.push({ name: "installasjon", params: { id: inst.id } });
    },
  },
  computed: {
    getImage() {
      return this.installation.img != undefined || this.installation.img != null
        ? this.installation.img
        : require("@/assets/fallback.jpg");
    },
  },
};
</script>

<style scoped lang="scss">
.Installation {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #2a8355;
  cursor: pointer;
  flex-basis: 10%;
}

.f-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.f-row {
  height: 100%;
  display: flex;
  flex-direction: column;
}

h3,
p {
  margin: 0;
  padding: 10px;
  color: #fff;
}
.desc {
  display: flex;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 1em;
  color: white;
  z-index: 1;
}
.Installation:hover {
  background-color: #60c090;
}
img {
  height: 30vw;
  width: 30vw;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .Installation {
    height: 100%;
    width: 100%;
  }
  img {
  height: 50vw;
  width: 100%;
  object-fit: contain;
  }
}
</style>
