<template>
  <div class="row" v-if="installations.length > 0">
    <div class="col-12 filter">
      <input
        type="text"
        placeholder="Søk etter installasjon"
        v-model="search"
        class="search"
      />
      <div class="found">{{ itemsFound }}</div>
      <select v-model="perPage" class="select">
        <option value="3">3</option>
        <option value="6">6</option>
        <option value="12">12</option>
      </select>
    </div>
    <KeepAlive>
      <div class="col-12">
        <div class="card-grid">
          <Installation
            :installation="installation"
            v-for="installation in paginatedArray"
            :key="installation"
          />
        </div>
      </div>
    </KeepAlive>
    <div class="col-12 center">
      <div class="pagination">
        <div>
          <button
            class="sButtons"
            @click="goToFirstPage()"
            :disabled="!previousPage"
          >
            Første
          </button>
          <button
            class="sButtons"
            @click="goToPreviousPage()"
            :disabled="!previousPage"
          >
            Forrige
          </button>
        </div>
        <div class="pagButtons">
          <button
            class="xButton"
            v-for="n in pageLimit"
            :key="n"
            @click="goToSelectedPage(n)"
            :style="{
              backgroundColor: n === page ? '#2a8355' : '#ffffff',
              color: n === page ? 'white' : 'black',
            }"
          >
            {{ n }}
          </button>
        </div>
        <div>
          <button
            class="sButtons"
            @click="goToNextPage()"
            :disabled="!nextPage"
          >
            Neste
          </button>
          <button
            class="sButtons"
            @click="goToLastPage()"
            :disabled="!nextPage"
          >
            Siste
          </button>
        </div>
      </div>
    </div>
  </div>
  <LoadingXD v-else />
</template>

<script>
import Installation from "@/components/InstallationXD.vue";
import LoadingXD from "@/components/LoadingXD.vue";

export default {
  name: "InstallationsView",
  components: {
    Installation,
    LoadingXD,
  },
  data() {
    return {
      installations: [],
      page: this.$route.query.side || 1,
      search: "",
      itemsFound: 0,
      totalPages: 0,
      perPage: 3,
      previousPage: null,
      nextPage: null,
      buttonsToShow: 5,
    };
  },
  async mounted() {
    await this.getInstallations();
  },
  methods: {
    async getInstallations() {
      await this.$store.dispatch("getInstallations");
      this.installations = await this.$store.state.installations;
    },

    filterArray() {
      const filterArray = this.installations
        .sort((a, b) => a.id - b.id)
        .filter(
          (obj) =>
            obj.title.toLowerCase().includes(this.search.toLowerCase()) ||
            obj.id.toString().includes(this.search)
        );
      this.itemsFound = filterArray.length;
      this.totalPages = Math.ceil(filterArray.length / this.perPage);
      this.previousPage = this.page - 1 ? this.page - 1 : null;
      this.nextPage = this.totalPages > this.page ? this.page + 1 : null;
      return filterArray;
    },
    goToFirstPage() {
      this.page = 1;
      this.goToCorrectRouteQuery();
    },
    goToLastPage() {
      this.page = this.totalPages;
      this.goToCorrectRouteQuery();
    },
    goToNextPage() {
      this.page++;
      this.goToCorrectRouteQuery();
    },
    goToPreviousPage() {
      this.page--;
      this.goToCorrectRouteQuery();
    },
    goToSelectedPage(n) {
      this.page = n;
      this.goToCorrectRouteQuery();
    },
    goToCorrectRouteQuery() {
      this.$router.replace({
        name: "installasjonene",
        query: { side: this.page },
      });
    },
  },
  computed: {
    paginatedArray() {
      const offset = this.perPage * (this.page - 1);
      return this.filterArray().slice(offset, this.perPage * this.page);
    },
    pageLimit() {
      let numShown = this.buttonsToShow; // This sets the number of page tabs
      numShown = Math.min(numShown, this.totalPages);
      let first = this.page - Math.floor(numShown / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPages - numShown + 1);
      return [...Array(numShown)].map((k, i) => i + first);
    },
  },
  watch: {
    search() {
      this.page = 1;
    },
    perPage() {
      this.page = 1;
    },
  },
};
</script>


<style scoped>
.filter {
  height: 2em;
  display: grid;
  grid-template-columns: 70% 10% 20%;
  margin-bottom: 10px;
}
.search {
  border: solid black 1px;
  padding: 10px;
}
.select {
  border: solid black 1px;
  padding: 10px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.pagButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.sButtons {
  margin: 5px;
  padding: 10px;
  width: 65px;
  border: solid black 1px;
  background-color: #2a8355;
  color: white;
}
.xButton {
  padding: 10px;
  border: solid black 1px;
}
button {
  cursor: pointer;
}

.found {
  background-color: white;
  border: solid black 1px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-left: none;
}
.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
@media only screen and (max-width: 900px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
