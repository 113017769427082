<template>
  <div class="sticky">
    <div class="AppBar">
      <img
        class="Logo"
        src="@/assets/poesiparken_hvit.png"
        tabindex="0"
        alt="Poesiparkens Logo"
        @click="goTo('/')"
        @keydown.enter="goTo('/')"
      />
      <div class="Menu" v-if="windowWidth > 768">
        <span
          class="MenuItem"
          tabindex="0"
          @keydown.enter="goTo('/installasjonene')"
          @click="goTo('/installasjonene')"
          >Installasjonene</span
        >
        <span
          class="MenuItem"
          tabindex="0"
          @keydown.enter="goTo('/aktuelt')"
          @click="goTo('/aktuelt')"
          >Aktuelt</span
        >
        <span
          class="MenuItem"
          tabindex="0"
          @keydown.enter="goTo('/om-poesiparken')"
          @click="goTo('/om-poesiparken')"
          >Om Poesiparken</span
        >
        <span
          class="MenuItem"
          tabindex="0"
          @keydown.enter="goTo('/kart')"
          @click="goTo('/kart')"
          >Kart</span
        >
        <span
          class="MenuItem"
          tabindex="0"
          @keydown.enter="goTo('/admin-panel')"
          @click="goTo('/admin-panel')"
          v-if="
            this.$store.state.user &&
            admins.indexOf(this.$store.state.user.uid) != -1
          "
          >Admin panel</span
        >
        <!-- <span class="MenuItem" v-if="!this.$store.state.user"  tabindex="1" @keydown.enter="goTo('/login')" @click="goTo('/login')">Logg inn</span> -->
      </div>
      <div class="MenuMobile" v-else @click="container = !container">
        <span class="material-icons">menu</span>
      </div>
    </div>
    <div class="MenuContainerWrapper" v-if="container && windowWidth < 768">
      <hr class="line" />
      <div class="MenuContainer">
        <span
          class="MenuItemMobile"
          tabindex="0"
          @keydown.enter="goTo('/installasjonene')"
          @click="goTo('/installasjonene')"
          >Installasjonene</span
        >
        <span
          class="MenuItemMobile"
          tabindex="0"
          @keydown.enter="goTo('/aktuelt')"
          @click="goTo('/aktuelt')"
          >Aktuelt</span
        >
        <span
          class="MenuItemMobile"
          tabindex="0"
          @keydown.enter="goTo('/om-poesiparken')"
          @click="goTo('/om-poesiparken')"
          >Om Poesiparken</span
        >
        <span
          class="MenuItemMobile"
          tabindex="0"
          @keydown.enter="goTo('/kart')"
          @click="goTo('/kart')"
          >Kart</span
        >
        <span
          class="MenuItemMobile"
          tabindex="0"
          @keydown.enter="goTo('/admin-panel')"
          @click="goTo('/admin-panel')"
          v-if="
            this.$store.state.user &&
            admins.indexOf(this.$store.state.user.uid) != -1
          "
          >Admin panel</span
        >
        <!-- <span class="MenuItemMobile" v-if="!this.$store.state.user" tabindex="1" @keydown.enter="goTo('/login')" @click="goTo('/login')">Logg inn</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppBar",
  props: {
    msg: String,
  },
  data: () => ({
    user: null,
    admins: [],
    windowWidth: window.innerWidth,
    container: false,
  }),
  created() {
    addEventListener(
      "resize",
      (event) => (this.windowWidth = event.target.innerWidth)
    );
  },
  mounted() {
    this.getAdmins();
  },
  methods: {
    async getAdmins() {
      await this.$store.dispatch("getAdmins");
      await this.$store.dispatch("getUser");
      this.admins = this.$store.state.admins;
      this.user = this.$store.state.user;
    },
    goTo(path) {
      this.$router.push({ path });
      this.container = false;
    },
  },
};
</script>

<style scoped lang="scss">
.AppBar {
  position: relative;
  z-index: 5;
  background-color: #2a8355;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Logo {
  height: 60px;
  cursor: pointer;
  user-select: none;
}
.Menu {
  height: 100%;
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
}
.MenuMobile {
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  user-select: none;
  border: solid 1px white;
}

.MenuMobile span {
  font-size: 50px;
}
.MenuItem {
  font-size: 20px;
  color: white;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}
.MenuItemMobile {
  // Might remove this and use 'MenuItem' on mobile and desktop
  font-size: 20px;
  color: white;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  background: #208753;
  padding: 10px;
}
.MenuItem:hover {
  text-decoration: underline;
}
.MenuContainer {
  color: white;
  display: grid;
  grid-auto-rows: min-content;
  gap: 10px;
  padding: 10px;
}
.MenuContainerWrapper {
  width: 100%;
  position: absolute;
  background-color: #2a8355;
  -webkit-animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.line {
  margin: 0px;
  border: solid 1px white;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-6-21 13:17:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
