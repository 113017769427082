<template>
  <ModalXD>
    <div class="installation-container" v-if="installation">
      <div class="input-wrapper">
        <h1 class="title">Rediger installasjon</h1>
      </div>
      <div class="input-wrapper">
        <div>
          <label for=""> ID: </label>
          <input type="number" placeholder="Id" v-model="installation.id" />
        </div>
        <div>
          <label> Tittel: </label>
          <input
            type="text"
            placeholder="Tittel"
            v-model="installation.title"
          />
        </div>
        <div>
          <label> Forfatter: </label>
          <input
            type="text"
            placeholder="Forfatter"
            v-model="installation.author"
          />
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Lese-Link: </label>
          <input type="text" placeholder="Link" v-model="installation.link" />
          <label> Lytte-Link: </label>
          <input
            type="text"
            placeholder="Link"
            v-model="installation.musicLink"
          />
        </div>
        <div>
          <label> Sted: </label>
          <input
            type="text"
            placeholder="Sted"
            v-model="installation.location"
          />
        </div>
        <div>
          <label> Koordinater: </label>
          <button @click="toggleGoogleMapsModal">Velg Koordinater</button>
          <span
            >Høyde: <input type="number" v-model="installation.position.lat"
          /></span>
          <br />
          <span
            >Bredde: <input type="number" v-model="installation.position.lng"
          /></span>
        </div>
      </div>
      <div class="input-wrapper">
        <div class="image-container" v-if="imageChanged">
          <p>Nytt bilde valgt</p>
        </div>
        <div class="image-container" v-else>
          <img
            :src="this.selectedInstallation.img"
            alt="Bilde"
            id="image-view"
          />
          <div></div>
          <div></div>
          <div></div>
        </div>
        <input
          type="file"
          ref="installationfile"
          class="image-selector"
          accept="image/*"
          @change="image = $event.target.files[0]"
        />
      </div>
      <div class="input-wrapper">
        <div>
          <label> Bidragsyter: </label>
          <input type="text" v-model="installation.sponsor" />
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Innhold: </label>
          <textarea
            class="txt-ara"
            placeholder="Innhold"
            v-model="installation.content"
          ></textarea>
        </div>
      </div>
      <div class="input-wrapper">
        <button @click="updateInstallation">
          Oppdater<span class="material-icons">publish</span>
        </button>
        <button @click="cancelUpdate" class="cancel-button">
          Avbryt<span class="material-icons">cancel</span>
        </button>
      </div>
      <div id="g-map-wrapper" v-if="gMapOpen">
        <GoogleMap
          id="g-map"
          class="map"
          api-key="AIzaSyAZx4cMuWZ8ZoqddymU6G_TYpu5D0NSofg"
          :center="center"
          :zoom="zoomLevel"
          mapTypeId="satellite"
          @click="getCoordinates"
        >
          <GmapCustomMarker :marker="installation.position">
            <img
              loading="lazy"
              src="@/assets/poesi-cursor.png"
              alt="bilde av kart-markør"
            />
          </GmapCustomMarker>
        </GoogleMap>
        <p>
          Valgte koordinater er:
          <span>Høyde: {{ installation.position.lat }}, </span>
          <span>Bredde: {{ installation.position.lng }}</span>
          <button class="close-modal-button" @click="toggleGoogleMapsModal">
            Bekreft koordinater
          </button>
        </p>
      </div>
    </div>
  </ModalXD>
</template>

<script>
import S3 from "@/exports/s3";
import ModalXD from "@/components/ModalXD.vue";
import { GoogleMap } from "vue3-google-map";
import GmapCustomMarker from "vue3-gmap-custom-marker";
import uniqid from "uniqid";
export default {
  name: "ModalInstallationEdit",
  components: {
    ModalXD,
    GoogleMap,
    GmapCustomMarker,
  },
  props: {
    selectedInstallation: Object,
  },
  data() {
    return {
      gMapOpen: false,
      image: null,
      center: { lat: 59.05328, lng: 10.03517 }, //Larvik, Norge
      zoomLevel: 12,
      selectedFile: null,
      installation: null,
      imageChanged: false,
      sponsors: [
        "Sparebank1",
        "Gjensidigestiftelsen",
        "Poesiparken",
        "Larvik kommune",
        "NLI/Poesiparken/Gjensidigestiftelsen",
        "Arnes Fargehandel",
        "Kaare Mortensen AS",
        "Lundhs AS",
        "Biblioteksystemer AS",
        "Larvik Boligbyggelag",
        "Larvikbanken",
        "Reguline AS. v/ Kari Line Roald",
        "Gave fra F.G.Fritzøe Minnefond",
        "Amfi Larvik",
        "Farris Bad",
        "Grand-kvartalet",
        "DNB, Ernst-Young AS, Abax AS",
        "NOPA, Lundhs AS, Hübert AS, Nasta AS og Bjørn og Øyvind Tveter AS",
        "Larvik Havn",
        "Ingers Hage",
        "LO-senteret",
        "Handelsbanken",
        "Ecbos legater",
        "Per Fuhre Pettersen",
        "Strandberg Stein og NOPA",
        "Vestfold kollektivtrafikk",
        "Lundhs AS Larvik Havn og Heyerdahlinstituttet",
        "Larvik og omegn Turistforening",
        "Larvik By og Poesiparkens Venner",
        "http://www.klemmetsby-firing.no/",
        "Tuppen Roalds barn",
        "Fritzøe Eiendom og Larvik kommune",
        "Bergene Holm AS",
        "Poesiparkens venner",
        "Danske Bank/Poesiparken",
        "Tore Hansen/Poesiparken",
        "Håkon Ebbing/Poesiparken",
      ],
    };
  },
  mounted() {
    this.getInstallation();
  },
  watch: {
    image() {
      this.imageChanged = true;
    },
  },
  methods: {
    getInstallation() {
      this.installation = this.selectedInstallation;
    },
    fileName(e) {
      this.selectedFile = e.target.files[0].name;
    },
    toggleGoogleMapsModal() {
      this.gMapOpen = !this.gMapOpen;
    },
    getCoordinates(e) {
      this.installation.position.lat = e.latLng.lat();
      this.installation.position.lng = e.latLng.lng();
    },
    updateInstallation() {
      if (this.imageChanged) {
        if (this.installation.img) {
          const decodedURI = (img) =>
            decodeURIComponent(`${img.split("/")[3]}/${img.split("/")[4]}`);
          S3.delete({
            Bucket: "poesiparken",
            Key: decodedURI(this.installation.img),
          }).then(() => {
            S3.upload({
              Bucket: "poesiparken",
              Key: `Installations/(${uniqid()})${this.image.name}`,
              Body: this.image,
              ContentType: this.image.type,
            }).then(async (data) => {
              let payload = this.installation;
              payload.img = data.Location;
              await this.$store.dispatch("updateInstallation", payload);
              this.$emit("close");
              this.imageChanged = false;
            });
          });
        } else {
          S3.upload({
            Bucket: "poesiparken",
            Key: `Installations/(${uniqid()})${this.image.name}`,
            Body: this.image,
            ContentType: this.image.type,
          }).then(async (data) => {
            let payload = this.installation;
            payload.img = data.Location;
            await this.$store.dispatch("updateInstallation", payload);
            this.$emit("close");
            this.imageChanged = false;
          });
        }
      } else {
        (async () => {
          let payload = this.installation;
          await this.$store.dispatch("updateInstallation", payload);
          this.$emit("close");
        })();
      }
    },
    cancelUpdate() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.installation-container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80vh;
  .input-wrapper {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 100%;
    * {
      width: 100%;
      margin: 5px;
      margin-right: 15px;
      padding: 0;
    }
    button {
      margin: 0px;
      padding: 20px;
      font-size: 1em;
      background: #2a8355;
      color: white;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      span {
        margin: 0 5px;
        width: fit-content;
      }
    }
    .cancel-button {
      background: #991111;
    }
  }
}

p {
  margin: 0;
  padding: 0;
  font-size: 1em;
}

.txt-ara {
  height: 6em;
  max-width: 100%;
  white-space: pre-wrap;
}

.custom-file-upload {
  padding: 20px;
  font-size: 1em;
  background: #2a8355;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#g-map-wrapper {
  position: absolute;
  height: 100vh;
  width: 80vw;
  top: 0vh;
  left: 10vw;
  margin: 0;
  padding: 0;
  background: #fff;
  #g-map {
    display: block;
    margin: 0;
    padding: 0;
    height: calc(100% - 4.5em);
    width: 100%;
  }
  p {
    margin: 0.5em;
    padding: 0.5em;
    text-align: right;
    font-size: 1em;
  }
  .close-modal-button {
    margin: 0.5em;
    padding: 0.5em;
    background-color: #2a8355;
  }
}

.image-container {
  display: flex;
  flex-direction: row;
  height: 64px;
  width: 64px;
}

.image-selector {
  width: 50px;
  border: 1px solid black;
  font-size: 0;
}

::file-selector-button {
  font-size: initial;
}

#image-view {
  height: 100%;
  width: 100%;
}
</style>
