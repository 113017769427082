<template>
  <ModalXD>
    <div class="person-container" v-if="person">
      <div class="input-wrapper">
        <h1 class="title">Oppdater person</h1>
      </div>
      <div class="input-wrapper">
        <div>
          <label>Posisjon:</label>
          <input type="number" placeholder="0" v-model="person.position" />
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Navn: </label>
          <input type="text" placeholder="Navn" v-model="person.name" />
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Tittel: </label>
          <input type="text" placeholder="Tittel" v-model="person.title" />
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Gruppe: </label>
          <select v-model="person.group">
            <option :value="group" v-for="(group, i) in groups" :key="i">
              {{ group }}
            </option>
          </select>
        </div>
      </div>
      <!-- <div class="input-wrapper">
        <div>
          <label> Tlf: </label>
          <input
            type="text"
            placeholder="Telefon-nummer"
            v-model="person.tlf"
          />
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Email: </label>
          <input
            type="text"
            placeholder="Email-adresse"
            v-model="person.email"
          />
        </div>
      </div> -->
      <div class="input-wrapper">
        <div>
          <label> Bildetager: </label>
          <input type="text" placeholder="Trengs bare hvis bildet er tatt profesjonelt" v-model="person.cred" />
        </div>
      </div>
      <div class="input-wrapper" v-if="person.group == 'Andre bidragsytere'">
        <div>
          <label> Beskrivelse: </label>
          <textarea placeholder="Beskrivelse" v-model="person.description">
          </textarea>
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Bilde: </label>
          <input
            @change="avatar = $event.target.files[0]"
            type="file"
            id="avatar"
            name="avatar"
            accept="image/png, image/jpeg"
          />
        </div>
      </div>
      <div class="input-wrapper">
        <button @click="updatePerson">
          Oppdater<span class="material-icons">publish</span>
        </button>
        <button @click="cancelUpdate" class="cancel-button">
          Avbryt<span class="material-icons">cancel</span>
        </button>
      </div>
    </div>
  </ModalXD>
</template>

<script>
import ModalXD from "@/components/ModalXD.vue";
import S3 from "@/exports/s3";
import uniqid from "uniqid";
export default {
  name: "ModalPersonEdit",
  components: {
    ModalXD,
  },
  props: {
    selectedPerson: Object,
  },
  data() {
    return {
      avatar: null,
      userTypes: ["Admin", "Redaktør", "Observatør"],
      groups: ["Styret", "Poesiparkens venner", "Andre bidragsytere"],
      person: null,
      avatarChanged: false,
    };
  },
  watch: {
    avatar() {
      this.avatarChanged = true;
    },
  },
  mounted() {
    this.getPerson();
  },
  methods: {
    getPerson() {
      this.person = this.selectedPerson;
    },
    fileName(e) {
      this.selectedFile = e.target.files[0].name;
    },
    updatePerson() {
      if (this.avatarChanged) {
        if (this.person.avatar) {
          const decodedURI = (img) =>
            decodeURIComponent(`${img.split("/")[3]}/${img.split("/")[4]}`);
          S3.delete({
            Bucket: "poesiparken",
            Key: decodedURI(this.person.avatar),
          })
            .then(() =>
              S3.upload({
                Bucket: "poesiparken",
                Key: `People/(${uniqid()})${this.avatar.name}`,
                Body: this.avatar,
                ContentType: this.avatar.type,
              })
                .then(async (data) => {
                  if (this.person.cred == "") this.person.cred = null;
                  let payload = this.person;
                  payload.avatar = data.Location;
                  await this.$store.dispatch("updatePerson", payload);
                  this.avatarChanged = false;
                  this.$emit("close");
                })
            )
        } else {
          S3.upload({
            Bucket: "poesiparken",
            Key: `People/(${uniqid()})${this.avatar.name}`,
            Body: this.avatar,
            ContentType: this.avatar.type,
          })
            .then(async (data) => {
              if (this.person.cred == "") this.person.cred = null;
              let payload = this.person;
              payload.avatar = data.Location;
              await this.$store.dispatch("updatePerson", payload);
              this.avatarChanged = false;
              this.$emit("close");
            })
        }
      } else {
        (async () => {
          let payload = this.person;
          await this.$store.dispatch("updatePerson", payload);
          this.$emit("close");
        })();
      }
    },
    cancelUpdate() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.person-container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  .input-wrapper {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 100%;
    * {
      width: 100%;
      margin: 5px;
      margin-right: 15px;
      padding: 0;
    }
    button {
      margin: 0px;
      padding: 20px;
      font-size: 1em;
      background: #2a8355;
      color: white;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      span {
        margin: 0 5px;
        width: fit-content;
      }
    }
    .cancel-button {
      background: #991111;
    }
  }
}

p {
  margin: 0;
  padding: 0;
  font-size: 1em;
}

.txt-ara {
  height: 10em;
  white-space: pre-wrap;
}

.custom-file-upload {
  padding: 20px;
  font-size: 1em;
  background: #2a8355;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
