<template>
    <div>
      <img :src="imageURL" alt="">
      <input type="file" accept="image/jpeg, image/png, image/jpg" @change="image = $event.target.files[0]">
      <button @click="upload">Upload</button>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
import S3 from "@/exports/s3";
  
  export default {
    data() {
      return {
        image: null,
        imageURL: null,
        message: null
      };
    },
    watch: {
      image(newImage) {
        if (newImage) {
          this.loadImage();
        }
      }
    },
    methods: {
      loadImage() {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageURL = reader.result;
        };
        reader.readAsDataURL(this.image);
      },
      upload() {
        // Må sette opp 'Public Access' til bucket før vi kan se bilder.
        // Ikke spam upload, koster penger om vi har for mange requests.
        // Tanken er at før du legger inne en installasjon så laster du opp bildet du har valgt
        // Du får tilbake url og lagrer den i firebase.
        if (this.image) {
          S3({
            Bucket: 'poesiparken',
            Key: `Installations/${this.image.name}`,
            Body: this.image,
            ContentType: this.image.type
          })
            .then((data) => {
                this.message = `SUCCESS: ${data.Location}`

                // firebase.insert({
                //     id: 0,
                //     title: 'Bla Bla',
                //     imageURL: data.Location
                // })
            })
            .catch((err) => this.message = `ERROR: ${err}`)
        } else this.message = 'ERROR: No image selected.'
      }
    },
  };
  </script>
  
  <style scoped>
  div {
    display: grid;
    grid-template-rows: repeat(4, min-content);
    gap: 10px;
  }

  img {
    height: 200px;
    object-fit: cover;
  }
</style>
  