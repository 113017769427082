<template>
  <div class="app-footer">
    <div class="footer-grid">
      <div class="col col-0"></div>
      <div class="col col-1">
        <h1>Kontakt oss</h1>
        <p>
          Feyers gate 7, Larvik
          <br />
          post@poesiparken.no
          <br />
          +47 905 27 679
        </p>
      </div>
      <div class="col col-2">
        <img
          :src="require('@/assets/sponsor-list/Larvik Kommune.png')"
          alt="Larvik kommune logo"
        />
      </div>
    </div>
    <div class="socials">
      <a
        href="https://www.instagram.com/poesiparken_larvik/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      > 
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 24 24"
        >
          <path
            fill="#fff"
            d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
          />
        </svg>
      </a>
      <a
        href="https://www.facebook.com/poesiparkensvenner/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
      > 
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
          version="1.2"
          baseProfile="tiny"
          x="0px"
          y="0px"
          width="36px"
          height="36px"
          viewBox="0 0 36 36"
          xml:space="preserve"
        >
          <defs></defs>
          <rect fill="#3B5998" width="36" height="36" />
          <path
            fill="#FFFFFF"
            d="M30.895,16.535l-0.553,5.23h-4.181v15.176h-6.28V21.766H16.75v-5.23h3.131v-3.149  c0-4.254,1.768-6.796,6.796-6.796h4.181v5.23h-2.615c-1.952,0-2.081,0.736-2.081,2.1v2.615H30.895z"
          />
        </svg>
      </a>
    </div>
    <div class="copyright">© Copyright Poesiparken 2022</div>
    <div class="copyright">Bilder er tatt av Terje Svendsen</div>
    <div class="copyright">
      <router-link to="/tilgjengelighetserklæring" class="tos-link"
        >Tilgjengelighetserklæring</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      routes: [
        {
          name: "Aktuelt",
          path: "/aktuelt",
        },
        {
          name: "Installasjonene",
          path: "/installasjonene",
        },
        {
          name: "Om oss",
          path: "/om-poesiparken",
        },
        {
          name: "Kart",
          path: "/kart",
        },
      ],
    };
  },
  methods: {
    goTo(path) {
      this.$router.push({ path });
      this.container = false;
    },
  },
};
</script>

<style scoped lang="scss">
.app-footer {
  background-color: #2a8355;
  padding: 50px;
  height: min-content;
  display: grid;
  gap: 10px;
  grid-template-rows: min-content auto auto;
  flex-shrink: 0;
}

.footer-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.copyright {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-0 {
  width: 20%;
}

.col-1 {
  width: 60%;
  text-align: center;
  color: white;

  h1 {
    font-size: 2em;
    margin: 0px;
  }

  p {
    margin: 0px;
  }
}

.col-2 {
  width: 20%;

  img {
    float: right;
    height: 80px;
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socials span {
  color: white;
}

.tos-link {
  font-size: 1.25em;
  color: #ffffff
}

@media only screen and (max-width: 768px) {
  .footer-grid {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .col {
    width: 100%;
    text-align: center;
    img {
      float: none;
    }
  }
}
</style>
