import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'material-icons/iconfont/material-icons.css'
import './assets/style.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { AVPlugin } from 'vue-audio-visual'
import VueGoogleMaps from '@fawmi/vue-google-maps' // Google maps plugin, for testing.
// import VueGoogleMaps from '@fawmi/vue-google-maps'

// axios.defaults.baseURL = "";

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-bootstrap.css';




const instance = axios.create();
const app = createApp(App)
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAZtHDjad-V098wNIY77lXulliMZVBXfIw',
        // language: 'de',
    },
})
app.use(AVPlugin)
app.use(store)
app.use(router)
app.use(VueAxios, { axios, instance })
app.use(ToastPlugin);
// app.use(VueGoogleMaps, {
//     load: {
//         key: 'YOUR_API_KEY_COMES_HERE',
//     },
// })
app.mount('#app')