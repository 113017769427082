<template>
  <div class="LatestNews" v-if="newsItems">
    <h2 class="instagram-link">
      Se mer på:
      <a
        href="https://www.instagram.com/poesiparken_larvik/"
        target="_blank"
        rel="noopener noreferrer"
        >Poesiparken's Instagram</a
      >
    </h2>
    <div
      class="News"
      v-for="(n, i) in sortedNewsItems"
      :key="i"
      @click="redirectToNewsItem(n)"
      @keypress.enter="redirectToNewsItem(n)"
      tabindex="0"
    >
      <img class="NewsImage" :src="n.img" :alt="n.description" />
      <div class="Content">
        <h1>{{ n.title }}</h1>
        <!-- <div class="author-and-timestamp-container">
          <p class="author-and-timestamp">
            <span class="author" v-if="n.author">Redaktør: {{ n.author }}</span>
            <br />
            <span class="timestamp" v-if="n.authorTimestamp">
              <span>{{
                ("0" + new Date(n.authorTimestamp).getHours()).slice(-2)
              }}</span
              >:<span>{{
                ("0" + new Date(n.authorTimestamp).getMinutes()).slice(-2)
              }}</span
              >:<span
                >{{
                  ("0" + new Date(n.authorTimestamp).getSeconds()).slice(-2)
                }}
                -
              </span>
              <span>
                {{
                  ("0" + new Date(n.authorTimestamp).getDate()).slice(-2)
                }}</span
              >/<span>{{
                ("0" + (new Date(n.authorTimestamp).getMonth() + 1)).slice(-2)
              }}</span
              >/<span>{{ new Date(n.authorTimestamp).getFullYear() }}</span>
            </span>
          </p>
        </div> -->
        <p>{{ truncate(n.content, 100) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LatestNews",
  props: {
    amount: String,
  },
  data: () => ({
    sortedNewsItems: null,
    newsItems: null,
  }),
  mounted() {
    this.getNewsItems();
  },
  methods: {
    async getNewsItems() {
      await this.$store.dispatch("getNewsItems");
      this.newsItems = await this.$store.state.newsItems;
      this.sortNews();
    },
    truncate(str, length) {
      const dots = str.length > length ? "..." : "";
      return str.substring(0, length) + dots;
    },
    sortNews() {
      let sortedNewsItems = this.newsItems
        .sort((a, b) => a.authorTimestamp - b.authorTimestamp)
        .reverse();
      this.sortedNewsItems = sortedNewsItems.slice(0, this.amount);
    },

    redirectToNewsItem(n) {
      this.$router.push({ name: "aktuelt", params: n });
    },
  },
};
</script>

<style scoped lang="scss">
.LatestNews {
  word-break: break-word;
}

.instagram-link {
  text-align: center;
}
.News {
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  height: 15em;
  width: 100%;
  background-color: #cbe3d7;
  color: black;
}
.News:nth-child(even) {
  background-color: #2a8355;
  color: white;
}
.NewsImage {
  width: 20em;
  object-fit: contain;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.Content {
  height: 100%;
  width: 70%;
  padding: 10px;
  text-align: left;
}
.Content h1 {
  font-size: 2em;
  margin: 0px;
}
.Content p {
  font-size: 1em;
  margin: 0px;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.author-and-timestamp-container {
  margin: 10px 0;
  width: 90%;
  overflow-wrap: break-word;
}
@media only screen and (max-width: 768px) {
  .News {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
  }
  .NewsImage {
    width: 100%;
    object-fit: contain;
  }
  .Content {
    height: 100%;
    width: 100%;
    padding: 10px;
    text-align: left;
  }
}
</style>
