<template>
  <div class="row">
    <div class="col-2"></div>
    <div
      class="col-8"
      tabindex="0"
      style="background-color: #ececec; padding: 10px"
    >
      <h4>Visjon: <br /> «Glede, trivsel og identitet via ordkunst»</h4>
      <p>
        Poesiparken er en annerledes opplevelsespark. Den består av ulike
        installasjoner i det offentlige rom, preget med dikt, sangtekster, poesi
        og litterære sitater. Teksten fremkommer på faste installasjoner som
        vegger, gatelegemet, torv, trapper osv., på ulikt formede steinelementer
        og på andre separate installasjoner i egnet materiale.
      </p>

      <h4>Poesiparkens formål</h4>

      <p>
        Alle som bor i og besøker Larvik kan oppleve glede og økt livskraft
        gjennom det Poesiparken formidler. Fastboende og besøkende berikes av
        dikt, poesi og litterære sitater i det offentlige rom. Installasjonene
        skaper ettertanke, refleksjon og et smil hos den enkelte, og gir en
        sanselig opplevelse her og nå. Poesiparken er tilgjengelig for alle midt
        i hverdagen. Den bidrar i tillegg til økt trivsel, kunnskap og leselyst.
        Poesiparken er en attraksjon for Larvik og bidrar til å utvikle og
        markedsføre Larvik med unike opplevelser.» Og kanskje aller først:
        Velkommen til Poesiparken på nett
      </p>
      <p style="color: #b00; font-weight: bold;">
        Nb: QR kodene virker ikke enda, men du kan høre opplesningen til
        installasjonen inne i denne applikasjonen.
      </p>
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8">
      <h1 class="title">Aktuelt</h1>
      <LatestNews amount="3" />
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8">
      <h1 class="title">Installasjoner</h1>
      <InstallationSlider />
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8">
      <h1 class="title">Bidragsytere</h1>
      <Sponsors />
    </div>
    <div class="col-2"></div>
  </div>
</template>

<script>
import LatestNews from "@/components/LatestNews.vue";
import InstallationSlider from "@/components/InstallationSlider.vue";
import Sponsors from "@/components/SponsorsSlide.vue";
export default {
  name: "HomeView",
  data() {
    return {
      texts: [],
    };
  },
  components: {
    LatestNews,
    InstallationSlider,
    Sponsors,
  },
};
</script>

<style scoped>
.title {
  text-align: left;
  font-size: 2.5em;
  margin: 0px;
  text-decoration: underline;
  text-decoration-color: #2a8355;
}
.desc {
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 10px;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
