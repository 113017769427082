<template>
  <div class="row">
    <div class="col-12">
      <LatestNews amount="6"/>
    </div>
  </div>
  <!-- <InstagramFeed>
    <InstagramPost @click="open = true"/>
  </InstagramFeed>
  <InstagramOverlay :open="open" @close="open = false" /> -->
</template>

<script>
import LatestNews from "@/components/LatestNews.vue";
// import InstagramPost from "@/components/InstagramPost.vue";
// import InstagramFeed from '@/components/InstagramFeed.vue'
// import InstagramOverlay from "@/components/InstagramOverlay.vue";
export default {
  name: "NewsView",
  components: {
    LatestNews,
    // InstagramPost,
    // InstagramFeed,
    // InstagramOverlay
  },
  data() {
    return {
      // modalOpen: false,
      // open: false
      newsItems: null,
    };
  },
  methods: {
    async getNewsItems() {
      await this.$store.dispatch("getNewsItems");
      this.newsItems = await this.$store.state.newsItems;
    },
    toggleNewsModal() {
      this.modalOpen = !this.modalOpen;
    },
    getInstagramMedia () {
      // Docs: https://developers.facebook.com/docs/instagram-api
      fetch('')
      .then((response) => response.json())
    },
  },
};
</script>

<style scoped>
.add-news-button-wrapper {
  text-align: right;
}

.add-news-button {
  margin: 1em 0;
  padding: 1em;
}
</style>
