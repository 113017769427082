<template>
  <div>
    <div v-if="data">
      <h2 tabindex="0">{{ data.title }}</h2>
      <div class="row">
        <p tabindex="0" class="col-8">{{ data.content }}</p>
        <div class="col-4">

          <img tabindex="0" class="news-image" :src="data.img" :alt="data.description" />
          <p tabindex="0">{{ data.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsItemView",
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    this.data = this.$router.currentRoute.value.params;
  },
  methods: {
    async getNewsItems() {
      await this.$store.dispatch("getNewsItems");
      this.newsItems = await this.$store.state.newsItems;
      this.sortNews();
    },
  },
};
</script>

<style scoped>
.news-image {
  width: 100%;
  object-fit: contain;
}
</style>