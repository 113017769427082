<template>
  <!-- <div class="row">
    <div class="col-12">
      <div class="grid">
        <input
          type="text"
          placeholder="Username"
          v-model="loginData.username"
        />
        <input
          type="password"
          placeholder="Password"
          v-model="loginData.password"
        />
        <button @click="login">
          Login <span class="material-icons">login</span>
        </button>
      </div>
    </div>
  </div> -->
  <div>
    <h1>Logg inn med google</h1>
    <button @click="loginWithFirebase">Logg inn</button>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      user: null,
    };
  },
  methods: {
    async loginWithFirebase() {
      await this.$store.dispatch("loginWithFirebase");
      await this.getUser();
    },
    async getUser() {
      await this.$store.dispatch("getUser");
      this.user = await this.$store.state.user;
      if (this.user) {
        this.$router.push({ name: "Admin panel" });
      }
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-auto-flow: row;
  gap: 40px;
  padding: 20px;
}
input {
  padding: 20px;
  font-size: 1em;
  background: transparent;
  border: none;
  border-bottom: solid 1px black;
}
button {
  padding: 20px;
  font-size: 1em;
  background: #2a8355;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
