<template>
  <div class="content-container">
    <h1>Om Poesiparken</h1>
    <div tabindex="0" style="background-color: #ececec; padding: 10px">
      <p>
        Poesiparken ble startet i 2006 – et litteratur- og
        stedsutviklingskonsept som består i å kle byen med dikt og sitater som
        skal gi folk et løft i hverdagen. Kommunen, næringslivet og
        frivilligheten har sammen løftet prosjektet til å bli trolig Norges
        største utendørs samling av poesiinstallasjoner. Folk kan gå på egne
        poesivandringer og bruke QR-koder til hvert dikt – eller de kan delta på
        Poesiparkens Venners vandringer sommerstid. Næringsliv og organisasjoner
        benytter slike arrangerte vandringer som aktiviteter ved kurs og
        konferanser. Poesiparken er blitt den stille røst i bybildet den var
        ment å være, og rommer i dag ca 115 installasjoner til glede for
        innbyggere og turister. Larvik er den byen i landet som har rommet flest
        diktere og forfattere. Å hogge i stein er en del av vår historie, og vi
        har den flotte bergarten Larvikitt som en av grunnpilarene i vår
        næringsdrift. Disse elementene utgjør Poesiparken i Larvik. Vi er blitt
        en by som snakker til folk gjennom poesiinstallasjoner på vegger, i
        gatelegemer, på murer og som egne installasjoner. Velkommen til lyriske
        vandringer i poesibyen Larvik!
      </p>
    </div>
    <h2>Styret:</h2>
    <div id="cards">
      <div
        class="card"
        v-for="person in getPeopleFromGroup('Styret')"
        :key="person"
      >
        <div class="avatar-wrapper">
          <img class="avatar" :src="getAvatar(person.avatar)" />
          <div class="overlay" v-if="person.cred">
            Bilde tatt av: {{ person.cred }}
          </div>
        </div>
        <div class="card-content">
          <span class="name">{{ person.name }}</span>
          <span class="title" v-if="person.title">{{ person.title }}</span>
          <span class="title" v-else>Ikke oppgitt</span>
        </div>
      </div>
    </div>
    <hr />
    <h2>Poesiparkens venner:</h2>
    <div tabindex="0" style="background-color: #ececec; padding: 10px">
      <p>
        Da Poesiparken tok sine første skritt og lærte å gå, ble det mye å gjøre
        på mange mennesker. Jobben var å bygge opp Poesiparken fra intet. I
        denne prosessen oppsto Poesiparkens Venner, en støtteforening som tar
        seg av poesikvelder, visekonserter m.m og arrangereer de populære
        poesi-vandringene. Poesiparkens Venner er også ivrig når det gjelde å
        støtte opp om Poesiparken økonomisk og skaffe sponsorer til nye
        installasjoner og arrangement, QR-kodeprosjekter osv. Blir du medlem,
        bidrar du kanskje til en ny installasjon. Venneforeningen gir sine
        medlemmer rabatterte billettpriser på konserter og lignende som er
        sosiale møteplasser for poesivennene. Du finner Poesiparkens Venner på
        Facebook. Bli gjerne medlem.
      </p>
    </div>
    <div id="cards">
      <div
        class="card"
        v-for="person in getPeopleFromGroup('Poesiparkens venner')"
        :key="person"
      >
        <div class="avatar-wrapper">
          <img class="avatar" :src="getAvatar(person.avatar)" />
          <div class="overlay" v-if="person.cred">
            Bilde tatt av: {{ person.cred }}
          </div>
        </div>
        <div class="card-content">
          <span class="name">{{ person.name }}</span>
          <span class="title" v-if="person.title">{{ person.title }}</span>
          <span class="title" v-else>Ikke oppgitt</span>
        </div>
      </div>
      <div class="pplogo-container">
        <img
          class="pplogo"
          :src="require('@/assets/Poesiparkens Venner Logo.jpg')"
          alt="Poesiparkens Venner logo"
        />
      </div>
    </div>
    <hr />
    <h2>Andre bidragsytere:</h2>
    <div id="cards">
      <div
        class="card"
        v-for="person in getPeopleFromGroup('Andre bidragsytere')"
        :key="person"
      >
        <div class="avatar-wrapper">
          <img class="avatar" :src="getAvatar(person.avatar)" />
          <div class="overlay" v-if="person.cred">
            Bilde tatt av: {{ person.cred }}
          </div>
        </div>
        <div class="card-content">
          <span class="name">{{ person.name }}</span>
          <span class="title" v-if="person.title">{{ person.title }}</span>
          <span class="title" v-else>Ikke oppgitt</span>
          <p class="person-description">{{ person.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PersonCard from "@/components/PersonCard.vue";
export default {
  name: "AboutView",
  // components: {
  //   PersonCard,
  // },
  data() {
    return {
      people: [],
    };
  },
  mounted() {
    this.getPeople();
  },
  methods: {
    async getPeople() {
      await this.$store.dispatch("getPeople");
      this.people = await this.$store.state.people;
    },
    getPeopleFromGroup(group) {
      return this.people
        .filter((p) => p.group === group)
        .sort((a, b) => a.position - b.position);
    },
    getAvatar(avatar) {
      return avatar ? avatar : require("@/assets/fallback-installation.jpg");
    },
  },
};
</script>

<style scoped>
#cards {
  text-transform: uppercase;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
}

.avatar-wrapper {
  position: relative;
}
.avatar {
  display: block;
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
.overlay {
  position: absolute;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: white;
  text-align: center;
  font-family: "RobotoLight";
  font-size: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.2) 0px 0px 0px 1px;
  /* background-color: #60c090; */
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.name {
  display: block;
  text-align: center;
  font-family: "RobotoBold";
  font-size: 20px;
}

.title {
  display: block;
  text-align: center;
  font-family: "RobotoLight";
  font-size: 14px;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin: 10px 0px 10px 0px;
}

.value {
  display: block;
}

.label {
  font-family: "RobotoBold";
}

.person-description {
  text-transform: none;
}
.pplogo-container {
  margin: auto;
  width: 90%;
}
.pplogo {
  width: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 1800px) {
  #cards {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 1400px) {
  #cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  #cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
