import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFound from '@/views/NotFound.vue'
import NewsView from '@/views/NewsView.vue'
import InstallationsView from '@/views/InstallationsView.vue'
import AboutView from '@/views/AboutView.vue'
// import MapView from '@/views/MapView.vue'
import LoginView from '@/views/LoginView.vue'
import AdminView from '@/views/AdminView.vue'
import NewsItemView from '@/views/NewsItemView.vue'
import InstallationView from '@/views/InstallationView.vue'

import TestView from '@/views/TestView.vue'
import UplodadImage from '@/views/UplodadImage.vue'
import TermsOfService from '@/views/TermsOfService.vue'
import getCurrentUser from '@/exports/getCurrentUser'

import store from "@/store/index.js"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aktuelt',
    name: 'aktuel',
    component: NewsView
  },
  {
    path: '/installasjonene',
    name: 'installasjonene',
    component: InstallationsView
  },
  {
    path: '/om-poesiparken',
    name: 'om-poesiparken',
    component: AboutView
  },
  {
    path: '/kart',
    name: 'kart',
    component: TestView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/admin-panel',
    name: 'Admin panel',
    component: AdminView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound
  },
  {
    path: '/aktuelt/:title&:content&:img&:description',
    name: 'aktuelt',
    component: NewsItemView
  },
  {
    path: '/installasjon/:id',
    name: 'installasjon',
    component: InstallationView,
    props: true
  },
  {
    path: '/upload',
    name: 'upload',
    component: UplodadImage
  },
  {
    path: '/tilgjengelighetserklæring',
    name: 'tos',
    component: TermsOfService
  },

  // {
  //   path: '/test',
  //   name: 'test',
  //   component: TestView,
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach(async (to) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   if (requiresAuth && !await getCurrentUser()) {
//     return '/login';
//   } 
// })
router.beforeEach(async (to, from) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    const user = await getCurrentUser()
    if (!user) return from.path;
    else {
      const admins = await store.state.admins;
      const uids = [...await admins.map((user) => user.uid)];
      if (!uids.includes(user.uid)) return '/';
    }
  }
});
export default router
