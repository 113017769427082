import S3 from 'aws-sdk/clients/s3';

const AWSS3 = new S3({
  accessKeyId: 'AKIAW6MFQMBHXVEAB65P',
  secretAccessKey: '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',
  region: 'eu-north-1',
});

export default {
  upload: (uploadParams) => AWSS3.upload(uploadParams).promise(),
  delete: (deleteParams) => AWSS3.deleteObject(deleteParams).promise(),
};