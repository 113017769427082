<template>
  <div class="loading-container">
    <div class="loading">
      <div class="lds-ring" ref="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="loading-text">{{ text }}</p>
      <p class="tagline">Utforsker dikt fra Larvik, Norge</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingXD",
  props: {
    text: {
      type: String,
      default: "Laster...",
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.spinner.focus();
      document.body.style.cursor = "wait";
    });
  },
  beforeUnmount() {
    document.body.style.cursor = "default";
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background-color: #ffffff;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid;
  border-radius: 50%;
  border-color: #2a8355 transparent transparent transparent;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  font-size: 20px;
  color: #2a8355;
  font-weight: normal;
  animation: breathingEffect 2s ease-in-out infinite; /* Adjusted duration to 2s */
  background: linear-gradient(90deg, #2a8355, #60a36e);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes breathingEffect {
  0%, 100% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
}

.tagline {
  font-size: 14px;
  color: #2a8355;
  margin-top: 8px;
}
</style>
