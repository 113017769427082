<template>
  <div class="row">
    <div class="col-12">
      <GMapMap
        ref="myMapRef"
        :center="center"
        :zoom="15"
        map-type-id="satellite"
        :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: true,
          mapId: '60ee99bcb368f06d',
        }"
      >
        <GMapMarker
          :visible="m.visible"
          :key="index"
          :clickable="true"
          :label="{
            text: m.id.toString(),
            fontWeight: 'bold',
            fontSize: '14px',
            color: 'black',
          }"
          :position="m.position"
          :draggable="false"
          @click="openMarker(m, true)"
          v-for="(m, index) in markers"
        >
          <GMapInfoWindow
            style="text-align: center"
            :opened="m.active"
            :options="{
              pixelOffset: {
                width: 0,
                height: -5,
              },
            }"
          >
            <div>
              <img
                loading="lazy"
                :src="getImage(m)"
                style="width: 100%; max-height: 150px; object-fit: cover"
              />
              <p>
                <b>{{ m.author }}</b>
                <span v-if="myCords"
                  >({{ haversinex(m.position, myCords) }})</span
                >
                <br />
                {{ m.title }}
              </p>
              <div class="waytypes">
                <button
                  :disabled="!geoLocationOn"
                  class="wayBtn"
                  @click="showTheWay(m, 'WALKING', index)"
                >
                  <span class="material-icons">directions_walk</span>
                </button>
                <button
                  :disabled="!geoLocationOn"
                  class="wayBtn"
                  @click="showTheWay(m, 'DRIVING', index)"
                >
                  <span class="material-icons">directions_car</span>
                </button>
                <button
                  :disabled="!geoLocationOn"
                  class="wayBtn"
                  @click="showTheWay(m, 'BICYCLING', index)"
                >
                  <span class="material-icons">directions_bike</span>
                </button>
              </div>
              <br />
              <a :href="`/installasjon/${m.id}`">Gå Til Installasjon</a>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
        <GMapMarker
          :clickable="false"
          :icon="{
            url: require('../assets/user-cursor.png'),
            scaledSize: { width: 28.5, height: 35.5 },
          }"
          :position="myCords"
          :draggable="false"
        ></GMapMarker>
      </GMapMap>
    </div>
  </div>
</template>
  
  <script>

const haversine = require("haversine-distance");
export default {
  name: "TestView",
  data() {
    return {
      updatePosition: false,
      myCords: null,
      center: { lat: 59.05328, lng: 10.03517 },
      markers: [],
      google: null,
      activeButton: {
        ButtonIndex: -1,
      },
      geoLocationOn: false,
      search: "",
      author: null,
    };
  },
  created() {
    this.getInstallations();
    this.getUserGeoLocation(() => {
      this.$refs.myMapRef.$mapPromise.then(async (mapObject) => {
        const { DirectionsService, DirectionsRenderer } =
          await window.google.maps.importLibrary("routes");
        this.google = {
          ds: new DirectionsService(),
          dr: new DirectionsRenderer({
            map: mapObject,
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "red",
              geodesic: true,
            },
          }),
        };
      });
    });
  },
  methods: {
    showTheWay(marker, type, buttonIndex) {
      if (this.google) {
        this.google.ds
          .route({
            origin: this.myCords,
            destination: marker.position,
            travelMode: type,
          })
          .then((directionsResult) => {
            if (this.activeButton.buttonIndex === buttonIndex) {
              this.google.dr.setDirections({ routes: [] });
              this.activeButton = { buttonIndex: -1, show: false };
              this.markers.forEach((m) => (m.visible = true));
            } else {
              this.google.dr.setDirections(directionsResult);
              this.activeButton = { buttonIndex, show: true };
              this.markers.forEach((m) => {
                if (m != marker) m.visible = false;
              });
            }
          });
      }
    },
    haversinex(a, b) {
      const meters = haversine(a, b).toFixed(0);
      if (meters >= 1000) return `${(meters / 1000).toFixed(1)}KM`;
      else return `${meters}M`;
    },
    async getInstallations() {
      await this.$store.dispatch("getInstallations");
      this.markers = await this.$store.state.installations.map((m) => {
        return {
          title: m.title,
          position: m.position,
          active: false,
          id: m.id,
          author: m.author,
          visible: true,
          img: m.img ? m.img : null,
        };
      });
    },
    openMarker(marker) {
      if (marker.active) {
        marker.active = false;
      } else {
        this.markers.forEach((m) => (m.active = false));
        this.center = marker.position;
        marker.active = true;
      }
    },
    getImage(m) {
      return m.img ? m.img : require("../assets/fallback.jpg");
    },
    truncate(string = "", maxLength = 50) {
      return string.length > maxLength
        ? `${string.substring(0, maxLength)}…`
        : string;
    },
    getUserGeoLocation(cb) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((response) => {
          this.geoLocationOn = true;
          const { latitude, longitude } = response.coords;
          this.myCords = { lat: latitude, lng: longitude };
          this.center = { lat: latitude, lng: longitude };
          cb();
        });
      }
    },
  },
};
</script>
  <style scoped>
.vue-map-container {
  height: 60vh;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>