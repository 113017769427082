import Vuex from 'vuex'
import firebase from "@/database/db.js";

const fb = firebase.firestore();
const personColl = fb.collection("people");
const sponsorColl = fb.collection("sponsors");
const priceWinnerColl = fb.collection("priceWinners");
const installationColl = fb.collection("installations");
const newsItemColl = fb.collection("newsItems");
const adminColl = fb.collection("admins");

export default new Vuex.Store({
  state: {
    user: null,
    people: null,
    sponsors: null,
    priceWinners: null,
    installations: null,
    newsItems: null,
    admins: null,
  },
  getters: {
    getTest(state) {
      return state.test
    },
    getUser(state) {
      return state.user
    },
    getPeople(state) {
      return state.people
    },
    getSponsors(state) {
      return state.sponsors
    },
    getPriceWinners(state) {
      return state.priceWinners
    },
    getInstallations(state) {
      return state.installations
    },
    getNewsItems(state) {
      return state.newsItems
    },
    getAdmins(state) {
      return state.admins
    },
  },
  mutations: {
    SET_TEST(state, test) {
      state.test = test
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_PEOPLE(state, people) {
      state.people = people
    },
    SET_SPONSORS(state, sponsors) {
      state.sponsors = sponsors
    },
    SET_PRICE_WINNERS(state, priceWinners) {
      state.priceWinners = priceWinners
    },
    SET_INSTALLATIONS(state, installations) {
      state.installations = installations
    },
    SET_NEWS_ITEMS(state, newsItems) {
      state.newsItems = newsItems
    },
    SET_ADMINS(state, admins) {
      state.admins = admins
    },
  },
  actions: {
    async loginWithFirebase() {
      let provider = await new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(provider)
    },
    async getUser() {
      const user = await firebase.auth().currentUser
      if (user) {
        this.commit('SET_USER', user)
      } else {
        this.commit('SET_USER', null)
      }
    },
    async logoutWithFirebase() {
      await firebase.auth().signOut();
      this.commit('SET_USER', null)
    },
    async getPeople() {
      let people = [];
      await personColl.get().then((person) => {
        person.forEach((doc) => {
          people.push(doc.data())
        });
      }).then(this.commit('SET_PEOPLE', people))
    },
    async getSponsors() {
      let sponsors = [];
      await sponsorColl.get().then((sponsor) => {
        sponsor.forEach((doc) => {
          sponsors.push(doc.data())
        });
      }).then(this.commit('SET_SPONSORS', sponsors))
    },
    async getPriceWinners() {
      let priceWinners = [];
      await priceWinnerColl.get().then((priceWinner) => {
        priceWinner.forEach((doc) => {
          priceWinners.push(doc.data())
        });
      }).then(this.commit('SET_PRICE_WINNERS', priceWinners))
    },
    async getInstallations() {
      let installations = [];
      await installationColl.get().then((installation) => {
        installation.forEach((doc) => {
          installations.push(doc.data())
        });
      }).then(this.commit('SET_INSTALLATIONS', installations))
    },
    async getNewsItems() {
      let newsItems = [];
      await newsItemColl.get().then((newsItem) => {
        newsItem.forEach((doc) => {
          newsItems.push(doc.data())
        });
      }).then(this.commit('SET_NEWS_ITEMS', newsItems))
    },
    async getAdmins() {
      let admins = [];
      await adminColl.get().then((admin) => {
        admin.forEach((doc) => {
          admins.push(doc.data())
        });
      }).then(this.commit('SET_ADMINS', admins))
    },

    async addPerson(_e, payload) {
      let person = await personColl
        .doc()
      person.set({
        uniqid: payload.uniqid,
        userType: payload.userType,
        position: payload.position,
        name: payload.name,
        title: payload.title,
        group: payload.group,
        avatar: payload.avatar,
        tlf: payload.tlf,
        email: payload.email,
        cred: payload.cred,
        description: payload.description,
      })
    },
    async addSponsor(_e, payload) {
      let sponsor = await sponsorColl
        .doc()
      sponsor.set({
        uniqid: payload.uniqid,
        name: payload.name,
        img: payload.img,
        sort: payload.sort
      })
    },
    async addPriceWinner(_e, payload) {
      let winner = await priceWinnerColl
        .doc()
      winner.set({
        uniqid: payload.uniqid,
        name: payload.name,
        title: payload.title,
        img: payload.img,
        date: payload.date
      })
    },
    async addInstallation(_e, payload) {
      let installation = await installationColl
        .doc()
      installation.set({
        id: payload.id,
        title: payload.title,
        author: payload.author,
        content: payload.content,
        link: payload.link,
        img: payload.img,
        musicLink: payload.musicLink,
        sponsor: payload.sponsor,
        location: payload.location,
        position: payload.position,
        view: payload.view,
      })
    },
    async addNewsItem(_e, payload) {
      let newsItem = await newsItemColl
        .doc()
      newsItem.set({
        uniqid: payload.uniqid,
        title: payload.title,
        author: payload.author,
        content: payload.content,
        img: payload.img,
        description: payload.description,
        authorTimestamp: payload.authorTimestamp
      })
    },
    async updatePerson(_e, payload) {
      var inst;
      await personColl.where("uniqid", "==", payload.uniqid).get()
        .then(snapshot => {
          snapshot.forEach(person => {
            inst = person.id
          })
        })
      let data = await personColl
        .doc(inst);
      await data.set({
        uniqid: payload.uniqid,
        position: payload.position,
        name: payload.name,
        title: payload.title,
        group: payload.group,
        avatar: payload.avatar,
        tlf: payload.tlf,
        email: payload.email,
        cred: payload.cred,
      },
        { merge: true }
      )
    },
    async updateSponsor(_e, payload) {
      var spons;
      await sponsorColl.where("id", "==", payload.uniqid).get()
        .then(snapshot => {
          snapshot.forEach(sponsor => {
            spons = sponsor.id
          })
        })
      let data = await sponsorColl
        .doc(spons);
      await data.set({
        uniqid: payload.uniqid,
        name: payload.name,
        img: payload.img,
        sort: payload.sort
      },
        { merge: true }
      )
    },
    async updatePriceWinner(_e, payload) {
      var wnr;
      await priceWinnerColl.where("id", "==", payload.uniqid).get()
        .then(snapshot => {
          snapshot.forEach(winner => {
            wnr = winner.id
          })
        })
      let data = await priceWinnerColl
        .doc(wnr);
      await data.set({
        uniqid: payload.uniqid,
        name: payload.name,
        title: payload.title,
        img: payload.img,
        date: payload.date
      },
        { merge: true }
      )
    },
    async updateInstallation(_e, payload) {
      var inst;
      await installationColl.where("id", "==", payload.id).get()
        .then(snapshot => {
          snapshot.forEach(installation => {
            inst = installation.id
          })
        })
      let data = await installationColl
        .doc(inst);
      await data.set({
        id: payload.id,
        title: payload.title,
        author: payload.author,
        content: payload.content,
        link: payload.link,
        img: payload.img,
        musicLink: payload.musicLink,
        sponsor: payload.sponsor,
        location: payload.location,
        position: payload.position,
        view: payload.view,
      },
        { merge: true }
      )

    },
    async updateNewsItem(_e, payload) {
      var inst;
      await newsItemColl.where("uniqid", "==", payload.uniqid).get()
        .then(snapshot => {
          snapshot.forEach(newsItem => {
            inst = newsItem.id
          })
        })
      let data = await newsItemColl
        .doc(inst);
      await data.set({
        uniqid: payload.uniqid,
        title: payload.title,
        author: payload.author,
        content: payload.content,
        img: payload.img,
        description: payload.description,
        authorTimestamp: payload.authorTimestamp
      },
        { merge: true }
      )
    },
    async deleteInstallation(_e, payload) {
      var inst;
      await installationColl.where("id", "==", payload.id).get()
        .then(snapshot => {
          snapshot.forEach(installation => {
            inst = installation.id
          })
        })
      let data = await installationColl.doc(inst);
      await data.delete()
    },
    async deletePerson(_e, payload) {
      var inst;
      await personColl.where("uniqid", "==", payload.uniqid).get()
        .then(snapshot => {
          snapshot.forEach(person => {
            inst = person.id
          })
        })
      let data = await personColl.doc(inst);
      await data.delete()
    },
    async deleteNewsItem(_e, payload) {
      var inst;
      await newsItemColl.where("uniqid", "==", payload.uniqid).get()
        .then(snapshot => {
          snapshot.forEach(news => {
            inst = news.id
          })
        })
      let data = await newsItemColl.doc(inst);
      await data.delete()
    },
    async deleteSponsor(_e, payload) {
      var inst;
      await sponsorColl.where("uniqid", "==", payload.uniqid).get()
        .then(snapshot => {
          snapshot.forEach(sponsor => {
            inst = sponsor.uniqid
          })
        })
      let data = await sponsorColl.doc(inst);
      await data.delete()
    },
    async deletePriceWinner(_e, payload) {
      var inst;
      await priceWinnerColl.where("uniqid", "==", payload.uniqid).get()
        .then(snapshot => {
          snapshot.forEach(winner => {
            inst = winner.uniqid
          })
        })
      let data = await priceWinnerColl.doc(inst);
      await data.delete()
    },
  },
  modules: {}
})
