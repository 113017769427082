<template>
  <div id="app">
    <AppBar />
    <div class="Banner" v-if="$route.path === '/'"></div>
    <div class="container">
      <router-view />
    </div>
    <AppFooter />
  </div>
</template>


<script>
// @ is an alias to /src
import AppBar from "@/components/AppBar.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "App",
  components: {
    AppBar,
    AppFooter,
  },
  beforeCreate() {
    this.$store.state.user = localStorage.getItem("AUTH_TOKEN");
  },
  created() {
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("AUTH_TOKEN");
          this.$store.state.user = null;
        }
      }
    );
  },
};
</script>

<style lang="scss">
// body {
//   filter: grayscale(1);
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.Banner {
  height: 250px;
  background: url("./assets/banner.webp");
  background-position: 50% 60%;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: solid 5px #2a8355;
}

@media only screen and (max-width: 768px) {
  .Banner {
    height: 125px;
  }
}
</style>