<template>
  <div class="NotFound">
    <div class="row">
    <div class="col-12 title">
      <h1>404</h1>
    </div>
    <div class="col-12 desc">
      <p>Siden du prøver å finne eksisterer ikke.</p>
    </div>
    <div class="col-12 btn">
      <button @click="$router.push({path: '/'})">Gå tilbake til hjem</button>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
.NotFound {
  padding: 100px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:3em;
  text-decoration: underline;
  text-decoration-color: #2a8355;
}
.title h1 {margin: 0px}
.desc {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
}
.desc p {margin: 0px; white-space:nowrap;}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn button {
  white-space:nowrap;
  padding: 20px;
  color: white;
  font-size: 1.2em;
  background: #2a8355;
  border: none;
  cursor: pointer;
}
</style>
