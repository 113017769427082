<template>
  <div class="tos-container">
    <iframe
      class="iframe-tos"
      src="https://docs.google.com/document/d/e/2PACX-1vS6p0xU491rK1p36w3vgJ_gQq2f-DfL5CyZERNO78prDUiG5O1iaKEZmop0bHHs8IzjONjtclOOLRHG/pub?embedded=true"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style scoped lang="scss">
.iframe-tos {
  padding: 0;
  width: 100%;
  min-height: 250vh;
  min-width: 380px;
  .doc-content {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .iframe-tos {
    margin: -30px;
  }
}
</style>
