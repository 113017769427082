<template>
  <div class="overlay fade-in">
    <div class="modal">
      <div class="close">
        <span class="material-icons closeIcon" @click="$emit('close')"
          >close</span
        >
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalXD",
  data: () => ({}),
};
</script>

<style scoped lang="scss">
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: white;
  width: 70vw;
  padding: 30px;
}
.close {
  display: flex;
  justify-content: flex-end;
}
.closeIcon {
  border-radius: 50%;
  border: solid 3px #900000;
  font-size: 2em;
  color: #900000;
  cursor: pointer;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
