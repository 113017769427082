import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAZx4cMuWZ8ZoqddymU6G_TYpu5D0NSofg",
    authDomain: "poesiparken.firebaseapp.com",
    projectId: "poesiparken",
    storageBucket: "poesiparken.appspot.com",
    messagingSenderId: "585849861236",
    appId: "1:585849861236:web:c84d639de85f81b35c4d21",
    measurementId: "G-2W896EXPVQ"
};

firebase.initializeApp(firebaseConfig);

export default firebase;